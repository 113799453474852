export default {
  // header: "Main",
  children: [


    {
      id: "/dashboard",
      title: "Dashboard",
      icon: "Curved-Graph",
      navLink: "/dashboard",
    },
    


    {
      id: "drivers",
      title: "Drivers",
      icon: "Curved-AddUser",
      
      children: [
        {
          id: "drivers",
          title: "Add Driver",
          navLink: "/drivers/add",
        },
        {
          id: "drivers",
          title: "All Drivers",
          navLink: "/drivers/list",
        },
      ],
    },

     {
      id: "vehicle",
      title: "Vehicle", 
      icon: "Curved-Wallet",
      children: [
        {
          id: "vehicle",
          title: "Add Vehicle",
          navLink: "/vehicle/addVehicle",
        },
        {
          id: "vehicle",
          title: "All Vehicles",
          navLink: "/vehicle/listVehicle",
        },
        //    {
        //   id: "Service",
        //   title: "Vehicle Service",
        //   navLink: "/service/vehicle",
        // },
      ],
    },
     {
      id: "B2B",
      title: "B2B",
      icon: "Curved-People",
      children: [
        {
          id: "/company",
          title: "Add B2B",
          icon: "Curved-Work",
          navLink: "/company/add",
        },
    
        {
          id: "/company",
          title: "All B2B",
          icon: "Curved-Work",
          navLink: "/company/list",
        },

          {
      id: "driver",
      title: "Drivers",
      icon: "Curved-Work",
      children: [
        {
          id: "driver",
          title: "Add Driver",
          navLink: "/B2B/driver/add",
        },
        {
          id: "driver",
          title: "All Drivers",
          navLink: "/B2B/driver/lists",
        },
         
      ],
    },
         {
      id: "vehicles",
      title: "Vehicle",
      icon: "Curved-Document",
      children: [
        {
          id: "vehicles",
          title: "Add Vehicle",
          navLink: "/B2B/Vehicle/add",
        },
        {
          id: "vehicles",
          title: "All Vehicles",
          navLink: "/B2B/Vehicle/lists",
        },
       
      ],
    },


      ],
    },
    {
      id: "Service",
      title: "Maintenance",
      icon: "Curved-Filter",
      
      children: [
       
       
         {
          id: "Service",
          title: "Add Vehicle Maintenance",
          navLink: "/service/vehicle",
        },
         {
          id: "Service",
          title: "All Vehicle Maintenance",
          navLink: "/service/list",
        },
      ],
    },
    {
      id: "fuelCard",
      title: "Fuel Card",
      icon: "Curved-Filter",
      
      children: [
       
       
         {
          id: "fuelCard",
          title: "Add Fuel Card",
          navLink: "/fuelcard/add",
        },
         {
          id: "fuelCard",
          title: "All Fuel Card",
          navLink: "/fuelcard/list",
        },
      ],
    },
    //  Expense
     {
      id: "Expense",
      title: "Expense",
      icon: "Curved-EditSquare",
      
      children: [
        {
          id: "Expense",
          title: "Add Expense",
          navLink: "/expense/add",
        },
        {
          id: "Expense",
          title: "All Expenses",
          navLink: "/expense/list",
        },
        //  {
        //   id: "Service",
        //   title: "Vehicle Service",
        //   navLink: "/service/vehicle",
        // },
         
      ],
    },


      // Report
      {
      id: "Report",
      title: "Report",
      icon: "Curved-Filter",
      
      children: [
       
        {
          id: "Report",
          title: "All Report",
          navLink: "/report/view",
        
        },
        // {
        //   id: "Repoer View",
        //   title: "Old Report",
        //   navLink: "/report/viewold"
        // }
      ],
    },
    // Invoice
     {
      id: "Invoice",
      title: "Invoice",
      icon: "Curved-Wallet",
      
      children: [
        {
          id: "Invoice",
          title: "Invoice Result",
          navLink: "/table/invoice",
        },
        // {
        //   id: "Report",
        //   title: "View Report",
        //   navLink: "/report/view",
        
        // },
      ],
    },

       ///  Earning Report
       {
        id: "earningReport",
        title: "Earning Report",
        icon: "Curved-Wallet",
        
        children: [
          {
            id: "earningReport",
            title: "View Reports",
            navLink: "/earningReportlist",
          },
          // {
          //   id: "Report",
          //   title: "View Report",
          //   navLink: "/report/view",
          
          // },
        ],
      },

      /////efficiency report
      {
        id: "efficiencyReport",
        title: "Efficiency Report",
        icon: "Curved-Wallet",
        
        children: [
          {
            id: "efficiencyReport",
            title: "View Reports",
            navLink: "/efficiencyReport",
          },
          // {
          //   id: "Report",
          //   title: "View Report",
          //   navLink: "/report/view",
          
          // },
        ],
      },

    // import file
    {
      id: "Import",
      title: "Import Earning",
      icon: "Curved-EditSquare",
      
      children: [
        {
          id: "Import",
          title: "Import Files",
          navLink: "/Import/importFile",
        },
      ],
    },
    //  UberFile
    {
      id: "uberFile",
      title: "Imported Files",
      icon: "Curved-Wallet",
      
      children: [
        {
          id: "uberFile",
          title: "All Files",
          navLink: "/uberfile/listUberFile",
        },
        // {
        //   id: "Report",
        //   title: "View Report",
        //   navLink: "/report/view",
        
        // },
      ],
    },
   

 // uber and bolt data
      // {
      //   id: "uber",
      //   title: "Import Data",
      //   navLink: "/uberdata",
      //   icon: "Curved-Wallet",
      // },


      // {
      //   id: "bolt",
      //   title: "Bolt Data",
      //   navLink: "/boltdata",
      //   icon: "Curved-Wallet",

      // },

  ]
}